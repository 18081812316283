import { Box, Text } from 'theme-ui';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';

const OrderConfirmationContact = () => {
  const translate = useTranslate();

  return (
    <Box sx={{ width: ['100%', '35%'] }}>
      <Text as="h5" variant="h5" sx={{ marginBottom: ['2vh', '2rem'], fontSize: ['1.3rem', 'initial'] }}>
        {translate('confirmation.contact.title')}
      </Text>

      <Text as="p" sx={{ fontSize: ['1.45rem', 'initial'], a: { fontWeight: 'medium' } }}>
        {translate('confirmation.contact.text')}{' '}
        <a
          sx={{
            textDecoration: 'underline',
          }}
          href="mailto:team@omorpho.com"
        >
          team@omorpho.com
        </a>
      </Text>
    </Box>
  );
};

export default OrderConfirmationContact;
