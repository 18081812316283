import React from 'react';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import OrderConfirmationPage from '~/components/Order/Confirmation/Page';

/**
 * This is left for backwards compatibility only.
 *
 * New Starters should instead use Gatsby dynamic routes!
 * See packages/gatsby-starter-autonomy/src/pages/order/confirmation/[number]/[id].js
 *
 * Note: Switching to this new dynamic routing system will require that you set your
 * Chord OMS `STRIPE_SUCCESS_URL_FORMAT` environment variable to 'path' in your
 * Admin Store settings:
 *
 * STRIPE_SUCCESS_URL_FORMAT=pat
 *
 */

const OrderConfirmation = ({ location }) => {
  const translate = useTranslate();

  const params = new URLSearchParams(location.search);

  const number = params.get('number') || '';
  const id = params.get('id') || '';

  return (
    <Layout>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage orderNumber={number} checkoutSessionId={id} />
    </Layout>
  );
};

export default OrderConfirmation;
