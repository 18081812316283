import { Box, Flex, Text } from 'theme-ui';
import { useTranslate, utils } from '@chordcommerce/gatsby-theme-autonomy';
import buyNowPayLaterCalculate from '~/utils/buy-now-pay-later';

const RowWrapper = ({ children, forwardSx }) => (
  <Flex
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      my: '.15rem',
      ...forwardSx,
    }}
  >
    {children}
  </Flex>
);

const OrderConfirmationSummary = ({ cart = {} }) => {
  const translate = useTranslate();
  const { displayTotal, displayItemTotal, displayTaxTotal, displayShipTotal } = cart;

  const { getAllCartPromotionsForDisplay } = utils;
  const promotions = getAllCartPromotionsForDisplay(cart);

  const sum = promotions.reduce((sum, y) => sum + y.amount, 0);

  return (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: ['100%', '60%'],
        mb: ['2.5rem', '5.5rem'],
        fontSize: ['initial', '1.9rem'],
      }}
    >
      {displayItemTotal && (
        <RowWrapper forwardSx={{ mt: '0 !important' }}>
          <Text as="p">{translate('cart.subtotal')}</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayItemTotal}
          </Text>
        </RowWrapper>
      )}

      {promotions.length > 0 && (
        <RowWrapper>
          <Text as="p">Promotions</Text>

          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {buyNowPayLaterCalculate(sum, 1)}
          </Text>
        </RowWrapper>
      )}

      {displayShipTotal && (
        <RowWrapper>
          <Text as="p">Shipping</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayShipTotal}
          </Text>
        </RowWrapper>
      )}

      {displayTaxTotal && (
        <RowWrapper>
          <Text as="p">{translate('cart.tax')}</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayTaxTotal}
          </Text>
        </RowWrapper>
      )}

      <Box sx={{ borderBottom: '1px solid #5C604F20', my: ['2.5rem', '3.5rem'] }} />

      {displayTotal && (
        <RowWrapper forwardSx={{ p: { fontSize: ['2rem', '2.8rem'] } }}>
          <Text as="p">{translate('cart.total')}</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayTotal}
          </Text>
        </RowWrapper>
      )}
    </Flex>
  );
};

OrderConfirmationSummary.defaultProps = {
  cart: null,
};

export default OrderConfirmationSummary;
