import React from 'react';
import { Container, Text } from 'theme-ui';
import { Link } from 'gatsby';
import ContentSection from '~/components/Content/Section';
import ImgLoader from '~/components/Generic/ImgLoader';

const ErrorNotFound = React.memo(function ErrorNotFound({ page }) {
  const {
    hero: { coverImages, titleSmall, titleLarge, subtitle, callToAction },
    sections,
  } = page;

  return (
    <Container sx={{ height: 'fit-content' }}>
      <Container
        variant="fullwidth"
        sx={{
          position: 'relative',
          height: '55rem',
        }}
      >
        {coverImages?.length && (
          <ImgLoader
            image={coverImages[0]}
            forwardSx={{
              position: ['relative'],
              width: '100%',
              height: '100%',
              borderTopLeftRadius: 1,
              borderBottomRightRadius: 3,
              img: {
                filter: 'brightness(.75) grayscale(.2) blur(1px)',
              },
            }}
          />
        )}
        <Container
          variant="fullwidth"
          sx={{
            my: [null, '20px'],
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            left: 0,
            height: '100%',
            textAlign: 'center',
            color: 'white',
            textTransform: 'uppercase',
            'h1, h2': {
              fontSize: ['3.2rem', '4rem'],
              lineHeight: 1,
              '&:last-of-type': {
                mb: ['3.5rem'],
              },
            },
            p: { fontSize: ['1.6rem', '2rem'], width: ['85vw', '42.2rem'], mx: 'auto', mb: ['4.4rem'] },
            a: {
              variant: 'buttons.secondary',
              height: '4.8rem',
              width: '20.8rem',
              mx: 'auto',
            },
          }}
        >
          <Text as="h1">
            {titleSmall}
            <br />
            {titleLarge}
          </Text>
          <Text sx={{ fontSize: ['clamp(10px, 3.5vw,16px)', '18px'], pb: '44px' }}>{subtitle}</Text>
          <Link
            to={
              callToAction?.[0]?.page
                ? callToAction?.[0]?.page.slug === 'home'
                  ? '/'
                  : `/${callToAction?.[0]?.page.slug}/`
                : '/'
            }
          >
            {callToAction?.[0]?.text}
          </Link>
        </Container>
      </Container>
      {sections && (
        <Container
          variant="fullwidth"
          sx={{
            mt: '1rem',
            width: [null, '49.3rem'],
            header: { p: 0, m: 0, width: '100%', div: { display: 'block' } },
            div: { width: '100%', p: { fontSize: ['1.4rem'], textAlign: 'center' } },
          }}
        >
          {sections.map((section) => (
            <ContentSection
              section={section}
              key={section.id}
              forwardSx={{ p: { backgroundColor: 'cloud', margin: '0 !important', py: '4rem ' } }}
            />
          ))}
        </Container>
      )}
    </Container>
  );
});

export default ErrorNotFound;
