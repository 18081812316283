import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, Container, Flex } from 'theme-ui';
import SpinnerBar from '~/components/Spinner/loader';
import { useCart, useTranslate, useCheckout } from '@chordcommerce/gatsby-theme-autonomy';

// import OrderConfirmationReferralPrompt from '~/components/Order/Confirmation/ReferralPrompt';
import OrderConfirmationSummary from '~/components/Order/Confirmation/Summary';
import OrderConfirmationCart from '~/components/Order/Confirmation/Cart';
import OrderConfirmationDetails from '~/components/Order/Confirmation/Details';
import OrderConfirmationContact from '~/components/Order/Confirmation/Contact';
import { useLocalStorage } from '~/hooks/components/use-storage';

import ErrorNotFound from '~/components/Error/NotFound';
import CompletePurchasePixel from '~/scripts/NBCU/CompletePurchasePixel';

const OrderConfirmationPage = ({ page, orderNumber, checkoutSessionId }) => {
  const { value: cartNumber } = useLocalStorage('cart-number-check');

  if (cartNumber && cartNumber === orderNumber) {
    return <OrderConfirmationLoaded orderNumber={orderNumber} checkoutSessionId={checkoutSessionId} />;
  }
  if (cartNumber && cartNumber !== orderNumber) {
    return <ErrorNotFound page={page} />;
  }
  return null;
};

const OrderConfirmationLoaded = ({ orderNumber, checkoutSessionId }) => {
  const { remove: removeWomens } = useLocalStorage('womensSize');
  const { remove: removeMens } = useLocalStorage('mensSize');
  const { forgetCart } = useCart();
  const { finalizeCheckout } = useCheckout();
  const [cart, setCart] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);

  const counterState = useRef(0);

  useEffect(() => {
    if ((cart?.payments && cart?.payments.length > 0) || counterState.current > 3) {
      return;
    }

    finalizeCheckout({ orderNumber, checkoutSessionId })
      .then((c) => {
        setCart(c);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        removeMens();
        removeWomens();

        forgetCart();

        counterState.current++;
      });
  }, [finalizeCheckout, forgetCart, cart?.lineItems, cart?.payments]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const key = isClient ? 'client' : 'server';
  if (!isClient) return null;
  if (error) return <ConfirmationError error={error} />;
  if (isLoading || !cart)
    return (
      <Container sx={{ height: '75vh', position: 'relative', background: 'cloud' }}>
        <SpinnerBar absolute={true} />
      </Container>
    );

  return (
    <ConfirmationContainer key={key} cart={cart}>
      <CompletePurchasePixel orderId={orderNumber} amount={cart.itemTotal} />
      <OrderConfirmationDetails cart={cart} />

      <Text
        as="h2"
        variant="h5"
        sx={{
          textAlign: 'left',
          marginBottom: ['3rem', null, '5rem'],
        }}
      >
        Order Summary
      </Text>
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          pb: [0, '5.5rem'],
          gap: ['6rem', '13.9rem'],
          mb: ['2.5rem', '5.5rem'],
          alignItems: ['center', 'initial'],
          borderBottom: (t) => `1px solid ${t.colors.translucentOlive}`,
        }}
      >
        <OrderConfirmationCart cart={cart} />
        <OrderConfirmationSummary cart={cart} />
      </Flex>
      <Flex
        sx={{
          flexDirection: ['row'],
          justifyContent: 'space-between',
        }}
      >
        <OrderConfirmationContact />
      </Flex>
    </ConfirmationContainer>
  );
};

const ConfirmationError = ({ error }) => {
  const translate = useTranslate();

  return (
    <ConfirmationContainer loaded={false}>
      <Container sx={{ maxWidth: ['100%', null, '89.8rem'], p: ['4rem 2.4rem', null, '8rem 0rem'] }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Text variant="h3" sx={{ fontSize: ['3rem', '5.5rem'], textAlign: 'left' }} color="errorDark">
            {translate('error.api.default')}
          </Text>
          <Text as="p">{error.toString()}</Text>
        </Flex>
      </Container>
    </ConfirmationContainer>
  );
};

const ConfirmationContainer = ({ children, cart = {}, loaded = true }) => {
  const firstName = cart.billAddress?.name?.split(' ')[0] ?? '';

  return (
    <Container variant="fullwidth" sx={{ backgroundColor: 'cloud' }}>
      <Container sx={{ maxWidth: ['100%', null, '89.8rem'], p: ['4rem 2.4rem', null, '8rem 0rem'] }}>
        <Flex sx={{ flexDirection: 'column' }}>
          {loaded && (
            <>
              <Text
                as="h1"
                sx={{
                  fontSize: ['3rem', '5.5rem'],
                  textAlign: 'left',
                  pb: '7vh',
                  fontWeight: 'medium',
                  textTransform: 'uppercase',
                  lineHeight: 0.9,
                }}
              >
                {`Thank you for`}
                <br />
                {`your order, ${firstName}.`}
              </Text>
              <Box sx={{ mb: ['5.5rem'], borderBottom: (t) => `1px solid ${t.colors.translucentOlive}` }} />
            </>
          )}
          {children}
        </Flex>
      </Container>
    </Container>
  );
};

export default OrderConfirmationPage;
