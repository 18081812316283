const buyNowPayLaterCalculate = (
  cents: number,
  payments: number,
  { locale, currency = 'USD' } = { locale: 'en-US', currency: 'USD' }
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return formatter.format(cents / payments);
};

export default buyNowPayLaterCalculate;
