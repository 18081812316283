import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Grid, Box, Text } from 'theme-ui';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';

const OrderConfirmationDetails = ({ cart = {} }) => {
  const translate = useTranslate();

  const { email, number, payments, shipAddress, shipments } = cart;
  const numberWithoutPrefix = number.split('-')[1];

  return (
    <Grid
      sx={{
        gridTemplateAreas: [
          `
      "order order"
      "email email"
      "shipping payment"
    `,
          `
    "order email"
    "shipping payment"
  `,
        ],
        '& > div:nth-of-type(odd)': {
          mr: ['5vw', '15rem'],
        },
        '& > div:nth-of-type(-n + 2)': {
          mb: [0, '5.5rem'],
        },
        fontSize: [0, '1.6rem'],
        mb: ['5.5vh', '7rem'],
        pb: ['5.5vh', '7rem'],
        borderBottom: (t) => `1px solid ${t.colors.translucentOlive}`,
        '& > div': {
          '& > h4': { fontSize: ['1.3rem', 'initial'], mb: ['1.5rem'] },
          '& > p': { lineHeight: 1.25, p: 0, fontSize: ['1.4rem', 'initial'] },
        },
      }}
    >
      <Box
        sx={{
          gridArea: 'order',
          'h3, p': {
            fontSize: ['2.3rem !important', '3rem !important', '3.4rem !important'],
            textTransform: 'uppercase !important',
            lineHeight: '0.9 !important',
            fontWeight: 'bold !important',
          },
        }}
      >
        <Text as="h3">Order Number</Text>
        <Text as="p">{numberWithoutPrefix}</Text>
      </Box>
      <Box sx={{ gridArea: 'email', width: ['85%', 'initial'], mb: ['2.5vh'] }}>
        <Text as="p">
          {translate('confirmation.details.text')} <strong>{`${email}. `}</strong>
          {`${translate('confirmation.details.subtitle')}`}
        </Text>
      </Box>

      <Box sx={{ gridArea: 'shipping' }}>
        <Text as="h4" variant="h5">
          {translate('confirmation.details.shipping')}
        </Text>

        <Text as="p">
          {shipAddress.name}
          <br />
          {shipAddress.address1}, {shipAddress.address2 && `${shipAddress.address2}, `}
          <br />
          {shipAddress.city}, {shipAddress.stateText}, {shipAddress.zipcode}
        </Text>
      </Box>
      <Box sx={{ gridArea: 'payment' }}>
        {payments.length > 0 && (
          <Fragment>
            <Text as="h4" variant="h5">
              {translate('confirmation.details.payment')}
            </Text>
            <Text as="p" sx={{ textTransform: 'capitalize', mb: ['2.5vh', '4.5rem'] }} key={payments?.[0].id}>
              {payments?.[0].source.ccType} (**** {payments?.[0].source.lastDigits})
            </Text>
          </Fragment>
        )}

        {shipments.length > 0 && (
          <Fragment>
            <Text as="h4" variant="h5">
              {translate('confirmation.details.ship_method')}
            </Text>
            <Text as="p">{shipments?.[0].selectedShippingRate.name}</Text>
          </Fragment>
        )}
      </Box>
    </Grid>
  );
};

OrderConfirmationDetails.propTypes = {
  cart: PropTypes.shape({
    email: PropTypes.string,
    number: PropTypes.string,
    payments: PropTypes.array,
    shipAddress: PropTypes.shape({}),
  }),
};

export default OrderConfirmationDetails;
